import Link from 'next/link';
import * as Types from '../declarations';
import { getEmailAddress } from './EmailAddress';
import { getVersion } from '../lib/withVersion';
const Footer = () => {
  const version = getVersion();
  const isTrekking = version === Types.VERSION.TREKKING;
  const websiteName = `Guided ${isTrekking ? 'Trekking' : 'Peaks'}`;
  return <div id="footer" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
			<div id="footer-content-wrapper">
				{/* {version === Types.VERSION.TREKKING && (
     <>
     	Like climbing? Check out{' '}
     	<a href="https://www.guidedpeaks.com">Guided Peaks</a>
     </>
     )}
     {version === Types.VERSION.PEAKS && (
     <>
     	Like trekking? Check out{' '}
     	<a href="https://www.guidedtrekking.com">Guided Trekking</a>
     </>
     )}
     <br /> */}
				&copy; {new Date().getFullYear()} {websiteName}. All rights reserved,
				except where otherwise noted.
				<span>
					<Link href="/terms" className="link-group" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
						Terms
					</Link>
				</span>
				<span>
					<Link className="link-group" href={`mailto:${getEmailAddress(version)}`} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
						Contact
					</Link>
				</span>
				{!isTrekking && <span className="link-group">
						Join the&nbsp;
						<Link href={`https://www.facebook.com/groups/guidedpeaks`}>
							community on facebook
						</Link>
					</span>}
			</div>
		</div>;
};
export default Footer;