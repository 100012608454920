import React from 'react';
import * as MantineCore from '@mantine/core';
import Link from 'next/link';
import { useDisclosure } from '@mantine/hooks';
import * as Icons from 'tabler-icons-react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useIsMobile } from '../lib/useIsMobile';
import { VersionContext } from '../contexts/VersionContext';
import * as Selectors from '../redux/selectors';
import AccountModal from './AccountModal';
import CountryBadge from './CountryBadge';
import * as HelperUtil from '../lib/helper';
import * as Consts from 'consts';
const BrandTitle = () => {
  const isMobile = useIsMobile();
  const version = React.useContext(VersionContext)?.version;
  return <Link href="/" legacyBehavior data-sentry-element="Link" data-sentry-component="BrandTitle" data-sentry-source-file="Header.tsx">
			<a className="brand-title">
				<MantineCore.Image src="/mountains-sun.svg" alt="guided Trekking icon" width={28} height={28} radius={'lg'} data-sentry-element="unknown" data-sentry-source-file="Header.tsx" />
				<span className="brand-header-text">
					{isMobile && false ? version === 'TREKKING' ? 'GT' : 'GP' : `Guided ${version === 'TREKKING' ? 'Trekking' : 'Peaks'}`}
				</span>
			</a>
		</Link>;
};
const HeaderMegaMenu = () => {
  const router = useRouter();
  const [drawerOpened, {
    toggle: toggleDrawer,
    close: closeDrawer
  }] = useDisclosure(false);
  const authedUser = useSelector(Selectors.authedUser);
  const isAdmin = useSelector(Selectors.isUserAdmin);
  const version = React.useContext(VersionContext).version;
  const isPeaks = version === 'PEAKS';
  const mobileLinks = Consts.regions.map((region, idxRegion) => <div key={idxRegion}>
			{region.map((continent, idxContinent) => <div key={idxContinent}>
					<Link href={`/climbing/${HelperUtil.sluggify(continent.continent)}`}>
						<MantineCore.Text size="sm" fw={600} m="sm" className={classNames({
          'header-link': true,
          'active-link': ['/countries', `/[routeType]/[continent]`, `/[routeType]/[continent]/[countries]`].some(route => {
            return router.pathname === route;
          })
        })}>
							{continent.continent}
						</MantineCore.Text>
					</Link>
					<MantineCore.List listStyleType="none">
						{continent.countries.map(country => <MantineCore.List.Item key={country.label}>
								<Link href={country.href} className="header-link">
									<MantineCore.Text size="sm" m="xs" fw={500}>
										<CountryBadge isoCode={country.isoCode} />
									</MantineCore.Text>
								</Link>
							</MantineCore.List.Item>)}
					</MantineCore.List>
				</div>)}
		</div>);
  return <div id="header2" data-sentry-component="HeaderMegaMenu" data-sentry-source-file="Header.tsx">
			<header>
				<MantineCore.Group style={{
        justifyContent: 'space-between'
      }} h="100%" id="brand" data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
					<div style={{
          padding: '8px'
        }}>
						<BrandTitle data-sentry-element="BrandTitle" data-sentry-source-file="Header.tsx" />
					</div>

					<div className="mobile-hide">
						<MantineCore.Group data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
							<Link href="/expeditions" legacyBehavior data-sentry-element="Link" data-sentry-source-file="Header.tsx">
								<a className={'header-link'}>
									<MantineCore.Text size="sm" m="sm" fw={500}
                // className={classNames({
                // 	'active-link': router.pathname === '/expeditions',
                // })}
                className={classNames({
                  'header-link': true,
                  'active-link': ['/expeditions', `/[routeType]/[continent]/[countries]/[slug]`].some(route => {
                    return router.pathname === route;
                  })
                })} data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
										{isPeaks ? 'Explore peaks' : 'Explore treks'}
									</MantineCore.Text>
								</a>
							</Link>

							{isPeaks && <>
									<Link href="/guides" legacyBehavior>
										<a className={'header-link'}>
											<MantineCore.Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname.startsWith('/guides')
                  })}>
												Guides
											</MantineCore.Text>
										</a>
									</Link>

									<Link href="/articles" legacyBehavior>
										<a className={'header-link'}>
											<MantineCore.Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname.startsWith('/articles')
                  })}>
												Articles
											</MantineCore.Text>
										</a>
									</Link>

									<MantineCore.HoverCard position="bottom" radius="md" shadow="md" withinPortal>
										<MantineCore.HoverCard.Target>
											<Link href="/countries" className={'header-link'}>
												<MantineCore.Center inline>
													<MantineCore.Box component="span" mr={5}>
														<MantineCore.Text size="sm" m="sm" fw={500} className={classNames({
                          'header-link': true,
                          'active-link': router.pathname.startsWith('/countries') || router.pathname === `/[routeType]/[continent]/[countries]` || router.pathname === `/[routeType]/[continent]`
                        })}>
															Countries
														</MantineCore.Text>
													</MantineCore.Box>
													<Icons.ChevronDown style={{
                        width: MantineCore.rem(16),
                        height: MantineCore.rem(16)
                      }} />
												</MantineCore.Center>
											</Link>
										</MantineCore.HoverCard.Target>

										<MantineCore.HoverCard.Dropdown style={{
                  overflow: 'hidden',
                  width: '600px'
                }}>
											<MantineCore.SimpleGrid cols={3} spacing={0}>
												{mobileLinks}
											</MantineCore.SimpleGrid>
										</MantineCore.HoverCard.Dropdown>
									</MantineCore.HoverCard>

									<Link href="/about" legacyBehavior style={{
                margin: '16px'
              }}>
										<a className={'header-link'}>
											<MantineCore.Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname === '/about'
                  })}>
												About
											</MantineCore.Text>
										</a>
									</Link>
								</>}
						</MantineCore.Group>
					</div>

					<div className="mobile-hide">
						<MantineCore.Group p="xs" data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
							{isAdmin && <Link href="/admin" passHref>
									<MantineCore.Button title={`logged in as ${authedUser?.id} (${authedUser?.role})`}>
										Admin
									</MantineCore.Button>
								</Link>}
							<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
						</MantineCore.Group>
					</div>

					<MantineCore.Burger opened={drawerOpened} onClick={toggleDrawer} className="mobile-show" mr="sm" data-sentry-element="unknown" data-sentry-source-file="Header.tsx" />
				</MantineCore.Group>
			</header>

			<MantineCore.Drawer opened={drawerOpened} onClose={closeDrawer} size="100%" padding="md" title={<BrandTitle />} className="mobile-show" zIndex={10000} data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
				<>
					<MantineCore.Divider data-sentry-element="unknown" data-sentry-source-file="Header.tsx" />
					<MantineCore.ScrollArea h={`calc(100vh - ${MantineCore.rem(80)})`} mx="-md" p="md" data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
						<Link href="/expeditions" legacyBehavior style={{
            margin: '16px'
          }} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
							<a className={'header-link'}>
								<MantineCore.Text size="sm" fw={500} data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
									{isPeaks ? 'Explore peaks' : 'Explore treks'}
								</MantineCore.Text>
							</a>
						</Link>

						{isPeaks && <>
								<Link href="/guides" legacyBehavior style={{
              margin: '16px'
            }}>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" fw={500}>
											Guides
										</MantineCore.Text>
									</a>
								</Link>
								<Link href="/articles" legacyBehavior style={{
              margin: '16px'
            }}>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" fw={500}>
											Articles
										</MantineCore.Text>
									</a>
								</Link>
								<MantineCore.List style={{
              margin: '16px',
              marginLeft: 0
            }}>
									<MantineCore.Text size="sm" fw={500}>
										<Link href="/places" className={'header-link'} legacyBehavior style={{
                  margin: '16px',
                  marginLeft: 0
                }}>
											Places:
										</Link>
									</MantineCore.Text>
									{mobileLinks}
								</MantineCore.List>
								<Link href="/about" className={'header-link'} legacyBehavior style={{
              margin: '16px',
              marginLeft: 0
            }}>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" fw={500}>
											About
										</MantineCore.Text>
									</a>
								</Link>
								<MantineCore.Divider my="sm" />
							</>}
						<div className="header-mobile-button-group">
							<MantineCore.Group grow pb="xl" mt="xl" data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
								{isAdmin && <Link href="/admin" passHref>
										<MantineCore.Button fullWidth title={`logged in as ${authedUser?.id} (${authedUser?.role})`} className="header-mobile-button-group-button">
											Admin
										</MantineCore.Button>
									</Link>}
								<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
							</MantineCore.Group>
						</div>
					</MantineCore.ScrollArea>
				</>
			</MantineCore.Drawer>
		</div>;
};
export default HeaderMegaMenu;