import * as React from 'react';
import * as ReactRedux from 'react-redux';
import * as MantineForm from '@mantine/form';
import * as ApolloClient from '@apollo/client';
import * as MantineCore from '@mantine/core';
import { useRouter } from 'next/navigation';
import * as Icons from 'tabler-icons-react';
import * as Types from 'declarations';
import * as GQLQueries from '../lib/queries';
import { getVersion } from '../lib/withVersion';
import * as Actions from '../redux/actions';
const ROLE_GUIDE = 'GUIDE';
const ROLE_CLIMBER = 'CLIMBER';
const RegisterForm = ({
  onRegister
}: {
  onRegister: () => void;
}) => {
  const [errorDisplay, setErrorDisplay] = React.useState<string>('');
  const dispatch = ReactRedux.useDispatch();
  const {
    push
  } = useRouter();
  const version = getVersion();
  const form = MantineForm.useForm({
    initialValues: {
      email: '',
      password: '',
      passwordCheck: '',
      role: '',
      // Add role to initial values
      companyName: '',
      website: ''
    },
    validate: {
      email: value => !value ? 'Email is required' : !/^\S+@\S+$/.test(value) ? 'Invalid email' : null,
      password: value => !value && 'Password is required',
      passwordCheck: (value, values) => value !== values.password ? 'Passwords did not match' : null,
      role: value => !value && 'Please select one',
      companyName: (value, values) => values.role === ROLE_GUIDE && !value ? 'Company name is required' : null,
      website: (value, values) => values.role === ROLE_GUIDE && !value ? 'Website is required' : null
    },
    validateInputOnBlur: true,
    validateInputOnChange: true
  });
  const [registerUser, {
    error: userError,
    loading: isLoadingUser
  }] = ApolloClient.useMutation(GQLQueries.Mutations.REGISTER);
  const [registerGuide, {
    error: guideError,
    loading: isLoadingGuide
  }] = ApolloClient.useMutation(GQLQueries.Mutations.REGISTER_AS_GUIDE);
  const roles = [{
    value: '',
    label: 'Select role'
  },
  // Default blank option
  {
    value: ROLE_CLIMBER,
    label: 'Climber'
  }, ...(version === Types.VERSION.PEAKS ? [{
    value: ROLE_GUIDE,
    label: 'Guide'
  }] : [])];
  const handleSubmit = async (values: {
    email: string;
    password: string;
    role: string;
    companyName?: string;
    website?: string;
  }) => {
    setErrorDisplay('');
    const {
      email,
      password,
      role,
      companyName,
      website
    } = values;
    let response;
    if (role === ROLE_GUIDE) {
      response = await registerGuide({
        variables: {
          email,
          password,
          companyName,
          website
        }
      });
    } else {
      response = await registerUser({
        variables: {
          email,
          password
        }
      });
    }
    const {
      user,
      token
    } = response?.data?.register || response?.data?.registerGuide;
    if (!!user) {
      dispatch(Actions.attemptLoginSucceeded(token, user));
      onRegister();
      if (role === ROLE_GUIDE) {
        // forward to guide section `/guide`
        push('/guide');
      }
    }
    if (response?.data?.register?.error || response?.data?.registerGuide?.error) {
      setErrorDisplay(response.data.register?.error || response.data.registerGuide?.error);
    }
  };
  const displayError = userError?.message || guideError?.message || errorDisplay || undefined;
  return <>
			<form onSubmit={form.onSubmit(values => handleSubmit(values))}>
				<MantineCore.Flex gap="xs" justify="flex-start" direction="column" pt="md" data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx">
					<MantineCore.TextInput label="Email" type="email" {...form.getInputProps('email')}
        // placeholder={'your email address'}
        required data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx" />

					<MantineCore.PasswordInput label="Password" {...form.getInputProps('password')} required data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx" />
					<MantineCore.PasswordInput label="Confirm password" {...form.getInputProps('passwordCheck')} required data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx" />
					<MantineCore.Select label="Are you a climber or a guide?" data={roles} {...form.getInputProps('role')} required data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx" />
					{form.values.role === ROLE_GUIDE && <>
							<MantineCore.TextInput label="Company" type="text" {...form.getInputProps('companyName')} placeholder={'the name of your agency / business'} required />

							<MantineCore.TextInput label="Website" type="text" {...form.getInputProps('website')} placeholder={'a link to your website.'} required />
						</>}
					<MantineCore.Button type="submit" mt="lg" disabled={isLoadingUser || isLoadingGuide || !form.isValid()} data-sentry-element="unknown" data-sentry-source-file="RegisterForm.tsx">
						{isLoadingUser || isLoadingGuide ? <>
								Joining&nbsp;&nbsp;&nbsp;
								<MantineCore.Loader size="sm" variant="dots" color="gray" />
							</> : 'Join'}
					</MantineCore.Button>
					{displayError && <MantineCore.Alert icon={<Icons.AlertCircle size="1rem" />} title="Something went wrong" color="red">
							{displayError}
						</MantineCore.Alert>}
				</MantineCore.Flex>
			</form>
		</>;
};
export default RegisterForm;