import * as React from 'react';
import * as ReactRedux from 'react-redux';
import * as MantineForm from '@mantine/form';
import * as ApolloClient from '@apollo/client';
import * as MantineCore from '@mantine/core';
import * as Icons from 'tabler-icons-react';
import * as NextRouter from 'next/router';
import * as Types from 'declarations';
import * as GQLQueries from '../lib/queries';
import * as Actions from '../redux/actions';
const LoginForm = ({
  onLogin
}: {
  onLogin: () => void;
}) => {
  const [errorDisplay, setErrorDisplay] = React.useState<string>('');
  // todo: redundant?
  // const [isRedirecting, setIsRedirecting] = React.useState<boolean>(false)

  const dispatch = ReactRedux.useDispatch();
  const router = NextRouter.useRouter();
  const form = MantineForm.useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: value => !value ? 'Email is required' : !/^\S+@\S+$/.test(value) ? 'Invalid email' : null,
      password: value => !value && 'Password is required'
    },
    validateInputOnBlur: true,
    validateInputOnChange: true
  });
  const [login, {
    error,
    loading: isLoading
  }] = ApolloClient.useMutation(GQLQueries.Mutations.LOGIN);
  const handleSubmit = async (values: {
    email: string;
    password: string;
  }) => {
    setErrorDisplay('');
    const response = await login({
      variables: values
    });
    const {
      user,
      token
    } = response?.data?.login;
    if (!!user) {
      dispatch(Actions.attemptLoginSucceeded(token, user));

      // raise parent event
      onLogin();
      if (user.role === Types.App.DataTypes.UserRole.GUIDE) {
        router.push('/guide');
      }
    }
    if (response?.data?.login?.error) {
      setErrorDisplay(response.data.login.error);
    }
  };
  const displayError = error?.message || errorDisplay || undefined;
  return <form onSubmit={form.onSubmit(values => handleSubmit(values))} data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
			<MantineCore.Flex gap="xs" justify="flex-start" direction="column" pt="md" data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
				<MantineCore.TextInput label="Email" type="email" required {...form.getInputProps('email')}
      // placeholder={'the email address you signed up with'}
      data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx" />
				<MantineCore.PasswordInput label="Password" required {...form.getInputProps('password')} data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx" />
				<MantineCore.Button type="submit" mt="lg" disabled={isLoading || !form.isValid()} data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
					{isLoading ? <>
							Logging in&nbsp;&nbsp;&nbsp;
							<MantineCore.Loader size="sm" variant="dots" color="gray" />
						</> : 'Log in'}
				</MantineCore.Button>
				{displayError && <MantineCore.Alert icon={<Icons.AlertCircle size="1rem" />} title="Something went wrong" color="red">
						{displayError}
					</MantineCore.Alert>}
			</MantineCore.Flex>
		</form>;
};
export default LoginForm;