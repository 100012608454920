import Head from 'next/head';
import classNames from 'classnames';
import Header from './Header';
import BottomLeftUnits from './BottomLeftUnits';
import Footer from './Footer';
const PageTemplate = ({
  children,
  isFullWidth = false,
  isFullHeight = false,
  isWithoutTopMargin = false
}: {
  children: React.ReactNode;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  isWithoutTopMargin?: boolean;
}) => {
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="PageTemplate.tsx">
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" data-sentry-element="meta" data-sentry-source-file="PageTemplate.tsx" />
			</Head>
			<Header data-sentry-element="Header" data-sentry-source-file="PageTemplate.tsx" />
			<div className={classNames({
      container: true,
      'fixed-100-percent-height': isFullHeight,
      'page-column': !isFullWidth,
      'no-top-margin': isWithoutTopMargin
    })}>
				{children}
				<Footer data-sentry-element="Footer" data-sentry-source-file="PageTemplate.tsx" />
			</div>
			<BottomLeftUnits data-sentry-element="BottomLeftUnits" data-sentry-source-file="PageTemplate.tsx" />
		</>;
};
export default PageTemplate;