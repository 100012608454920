import classNames from 'classnames';
// @ts-ignore
import iso3311a2 from 'iso-country-lookup';
import * as HelperUtil from '../lib/helper';
const CountryBadge = ({
  isoCode,
  isHighlighted = false,
  classes
}: {
  isoCode: string;
  isHighlighted?: boolean;
  classes?: string[];
}) => {
  return <span className={classNames(...(classes ?? []), {
    'country-badge': true,
    highlighted: isHighlighted
  })} data-sentry-component="CountryBadge" data-sentry-source-file="CountryBadge.tsx">
			<img alt={iso3311a2.getCountry(isoCode.toUpperCase()) + ' flag'} src={HelperUtil.getFlagURL(isoCode)} className="flag" height={14} width={21} />
			{iso3311a2.getCountry(isoCode.toUpperCase())}
		</span>;
};
export default CountryBadge;